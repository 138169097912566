import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
    {
        path:'/',
        redirect:'/index'
    },
    {
        path: '/index',
        name: 'index',
        component: () => import('../views/IndexView.vue')
    },
    {
        path: '/news',
        name: 'news',
        component: () => import('../views/NewsView.vue')
    },
    {
        path: '/news/newsId/:newsId',
        name: 'newsDetails',
        component: () => import('../views/NewsDetailsView.vue')
    },
    {
        path: '/kc/kcId/:kcId',
        name: 'KcDetails',
        component: () => import('../views/KcDetailsView.vue')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('../views/AboutView.vue')
    },
    {
        path: '/contactUs',
        name: 'contactUs',
        component: () => import('../views/ContactView.vue')
    },
    {
        path: '/useragreement',
        name: 'useragreement',
        component: () => import('../views/useragreement.vue')
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: () => import('../views/privacy.vue')
    },
    {
        path: '/solemnlyDeclare',
        name: 'solemnlyDeclare',
        component: () => import('../views/SolemnlyDeclare.vue')
    }
]

const router = new VueRouter({
    routes
})

export default router
