<template>
	<div class="header-wrapper" >
		<div class="nav-wrapper header-default">
			<div class="nav">
				<div class="logo" @click="handlePage('/',1)">
					<img src="../../../assets/images/logo.png" alt="">
				</div>
				<div class="nav-ul">
					<div v-for="item in menuList" :class=" selectIndex == item.id ? 'nav-item actived' : 'nav-item'" @click="handlePage(item)" :key="item.id">
						{{item.name}}
					</div>
				</div>
				<div class="nav-right">
					<a class="hx-btn login-btn" href="https://www.daodaoming.com/index/user/login.html" target="_blank">登录</a>
					<a class="hx-btn register-btn" href="https://www.daodaoming.com/index/user/register.html"  target="_blank">注册</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import AppFunctions from "@/utils/AppFunctions";
export default {
	name: "Header",
	components: {},
	data(){
		return{
			AppFunctions,
			menuList:[
				{
					id: 1,
					name: '关于道道明',
					link: '/about'
				},
				{
					id: 2,
					name: '联系我们',
					link: '/contactUs'
				},
				{
					id: 3,
					name: '备考资料',
					src:'https://www.daodaoming.com/index/user/login.html'
				},
				{
					id: 4,
					name: '资讯中心',
					link: '/news'
				},
				{
					id: 5,
					name: '学习中心',
					src:'https://www.daodaoming.com/index/user/login.html'
				},
			],
			selectIndex: 0
		}
	},
	methods: {
		toggleStickyHeader() {
			const scrolled = document.documentElement.scrollTop;
			if (scrolled > 100) {
				AppFunctions.addClass('.header-default', 'sticky');
			} else if (scrolled <= 100) {
				AppFunctions.removeClass('.header-default', 'sticky');
			}
		},
		handlePage(item, type){
			if(type){
				this.$router.push(item)
				this.selectIndex = 0
			}else{
				console.log(this.selectIndex,'this.selectIndex')
				if(item.src){
					window.open(item.src, '_blank');
				}else{
					this.$router.push({path:item.link})
				}
				this.selectIndex = item.id
			}
		},
	},
	created() {
		window.addEventListener('scroll', this.toggleStickyHeader);
	},
	mounted() {
		this.toggleStickyHeader();
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.toggleStickyHeader);
	}

}
</script>

<style lang="less" scoped>
	.nav{
		display: flex;
		justify-content: space-between;
		align-content: center;
		max-width: 1200px;
		.logo{
			width: 200px;
			height: 60px;
			margin-right: 2em;
			cursor: pointer;

			img{
				display: block;
				max-width: 100%;
				max-height: 59px;
			}
		}

		&-ul{
			display: flex;
			justify-content: flex-start;
			align-items: center;
			flex: 1;
		}
		.nav-item {
			position: relative;
			cursor: pointer;
			color: #2e3033;
			font-size: 15px;
			overflow: hidden;
			text-overflow: ellipsis;
			word-break: break-all;
			white-space: nowrap;
			line-height: 60px;

			&:not(:first-child){
				margin-left: 10%;
			}

			&:hover,
			&.actived{
				font-weight: 700;
				color: #37f;
				position: relative;
			}
			&.actived:before {
				content: "";
				position: absolute;
				left: 50%;
				width: 28px;
				height: 2px;
				background: #37f;
				margin-left: -14px;
				bottom: 12px;
			}
		}

		.nav-right{
			line-height: 60px;
			.hx-btn {
				color: #fff;
				font-weight: 500;
				border: none;
				outline: none;
				cursor: pointer;
				display: inline-block;
				position: relative;

				font-size: 14px;
				border-radius: 30px;
				line-height: 14px;
				padding: 8px 21px;
				border: 1px solid #dde3f0;
				color: rgba(0,0,0,.65);

				&.register-btn:hover {
					border: 1px solid #37f;
					color: #37f;
				}

				&.login-btn{
					background: #37f;
					color: #fff;
					border: none;
					margin-right: 18px;
					&:hover {
						background: #6aa1fe;
					}
				}
			}
		}
	}
</style>
